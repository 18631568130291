import { createStore, Module } from "vuex";
import axios from "axios";
import { darkModeKey, styleKey, apiBasePath } from "@/config.js";
import * as styles from "@/styles";
import { showSuccess, showApiErrorResponse } from "@/notify";
import { calculateProfit } from "@/utils";
import authModule, { AuthState } from "@/store/auth";
import packageModule, { PackageState } from "./packages";
import presetModule, { PresetState } from "./presets";
import feeModule, { FeeState } from "./fees";
import giftCodeModule, { GiftCodeState } from "./giftCode";
import voucherModule, { VoucherState } from "./voucher";
import snipeSignalsModule from "@/snipeAutomation/signals/store";
import snipeAutomationConfigurationsModule from "@/snipeAutomation/configuration/store";
import userAdministrationModule, {
  UserAdministrationState,
} from "@/administration/user/store";
import copyTradeConfigurationModule, {
  CopyTradeConfigurationState,
} from "@/copyTrade/configuration/store";
import copyTradeAccountModule, {
  CopyTradeAccountState,
} from "@/copyTrade/accounts/store";
import notificationsModule, { NotificationsState } from "@/notifications/store";
import notificationAdministrationModule, {
  NotificationAdministrationState,
} from "@/administration/notification/store";
import statisticsAdministrationModule, { StatisticsAdministrationState } from "@/administration/statistics/store";

export enum SnipeState {
  CREATED = "created",
  PRE_BUY_TRIGGER_PAIR_CHECK = "pre-buy-trigger-pair-check",
  PRE_BUY_PAIR_CHECK = "pre-buy-pair-check",
  POST_BUY_PAIR_CHECK = "post-buy-pair-check",
  BUY_APPROVAL = "buy-approval",
  BUY_TRIGGER = "buy-trigger",
  BUY_RETRY_TRIGGER = "buy-retry-trigger",
  SELL_APPROVAL = "sell-approval",
  BUY = "buy",
  SELL = "sell",
  MANUAL_SELL_APPROVAL = "manual-sell-approval",
  MANUAL_SELL = "manual-sell",
  DONE = "done",
  PARTIALLY_FILLED = "partially-filled",
  FAILED = "failed",
  CANCELLED = "cancelled",
}

export const finalSnipeStates: SnipeState[] = [
  SnipeState.DONE,
  SnipeState.PARTIALLY_FILLED,
  SnipeState.FAILED,
  SnipeState.CANCELLED,
];

export interface RootState {
  lightBorderStyle: string;
  lightBgStyle: string;
  asideStyle: string;
  asideBrandStyle: string;
  asideMenuCloseLgStyle: string;
  asideMenuLabelStyle: string;
  asideMenuItemStyle: string;
  asideMenuItemActiveStyle: string;
  asideMenuItemInactiveStyle: string;
  asideSubmenuListStyle: string;
  navBarItemLabelStyle: string;
  navBarItemLabelHoverStyle: string;
  navBarItemLabelActiveColorStyle: string;
  navBarMenuListUpperLabelStyle: string;
  tableTrStyle: string;
  tableTrOddStyle: string;
  overlayStyle: string;

  /* User */
  userId?: string;
  userName?: string;
  userEmail?: string;
  userAvatar?: string;
  userToken?: string;
  userPackageId?: number;
  userRoles: string[];

  /* fullScreen - fullscreen form layout (e.g. login page) */
  isFullScreen: boolean;

  /* Aside */
  isAsideMobileExpanded: boolean;
  isAsideLgActive: boolean;

  /* Dark mode */
  darkMode: boolean;

  /* Field focus with ctrl+k (to register only once) */
  isFieldFocusRegistered: boolean;

  showFloatingButtons: boolean;

  showHiddenBalances: boolean;
  chain?: string;

  initialLoadRunning: boolean;
  locale: string;

  accounts: any[];
  snipes: any[];
  snipeStatistics: any;
  transactions: any[];
  withdrawals: any[];
  deposits: any[];
  prices: any;
  chainDetails?: any;
  tokenInfo: any;

  auth: AuthState;
  packages: PackageState;
  presets: PresetState;
  fees: FeeState;
  giftCodes: GiftCodeState;
  vouchers: VoucherState;
  userAdministration: UserAdministrationState;
  copyTradeConfiguration: CopyTradeConfigurationState;
  copyTradeAccount: CopyTradeAccountState;
  notifications: NotificationsState;
  notificationAdministration: NotificationAdministrationState;
  statisticsAdministration: StatisticsAdministrationState;
}

const state: any = {
  /* Styles */
  lightBorderStyle: "",
  lightBgStyle: "",
  asideStyle: "",
  asideBrandStyle: "",
  asideMenuCloseLgStyle: "",
  asideMenuLabelStyle: "",
  asideMenuItemStyle: "",
  asideMenuItemActiveStyle: "",
  asideMenuItemInactiveStyle: "",
  asideSubmenuListStyle: "",
  navBarItemLabelStyle: "",
  navBarItemLabelHoverStyle: "",
  navBarItemLabelActiveColorStyle: "",
  navBarMenuListUpperLabelStyle: "",
  tableTrStyle: "",
  tableTrOddStyle: "",
  overlayStyle: "",

  /* User */
  userId: null,
  userName: null,
  userEmail: null,
  userAvatar: null,
  userToken: null,
  userRoles: [],

  /* fullScreen - fullscreen form layout (e.g. login page) */
  isFullScreen: true,

  /* Aside */
  isAsideMobileExpanded: false,
  isAsideLgActive: false,

  /* Dark mode */
  darkMode: true,

  /* Field focus with ctrl+k (to register only once) */
  isFieldFocusRegistered: false,

  showFloatingButtons: true,

  initialLoadRunning: true,

  showHiddenBalances: false,
  chain: undefined,

  accounts: [],
  snipes: [],
  snipeStatistics: undefined,
  transactions: [],
  withdrawals: [],
  deposits: [],
  prices: {},
  chainDetails: undefined,
  tokenInfo: {},
};

export default createStore({
  state,
  getters: {
    chainDetails(state) {
      if (state.chain) {
        return state.chainDetails[state.chain];
      }
    },
    nonDeletedSnipes(state) {
      return state.snipes.filter((snipe) => !snipe.deletedAt);
    },
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload: { key: string; value: any }) {
      // @ts-ignore
      state[payload.key] = payload.value;
    },

    /* Styles */
    styles(state, payload) {
      for (const key in payload) {
        if (["body", "html"].includes(key)) {
          continue;
        }

        // @ts-ignore
        state[`${key}Style`] = payload[key];
      }
    },

    /* User */
    user(state, payload) {
      if (payload.id) {
        state.userId = payload.id;
      }
      if (payload.name) {
        state.userName = payload.name;
      }
      if (payload.email) {
        state.userEmail = payload.email;
      }
      if (payload.roles) {
        state.userRoles = payload.roles;
      }
      if (payload.twoFactorAuthenticationEnabled) {
        state.auth.twoFactorAuthenticationEnabled =
          payload.twoFactorAuthenticationEnabled;
      }
    },

    account(state, payload) {
      const account = state.accounts.find(
        (account: any) => account.publicKey === payload.publicKey
      );

      account.name = payload.name ? payload.name : undefined;
    },

    addAccount(state, payload) {
      const accountIndex = state.accounts.findIndex(
        (account: any) =>
          account.publicKey === payload.publicKey &&
          account.chain === state.chain
      );
      if (accountIndex < 0) {
        state.accounts.push(payload);
      } else {
        state.accounts[accountIndex] = payload;
      }
    },

    updateBalances(state, payload: any) {
      for (const [publicKey, balances] of Object.entries(
        payload.balances
      ) as any) {
        const account = state.accounts.find(
          (account: any) =>
            payload.chain === account.chain && publicKey === account.publicKey
        );

        if (!account) {
          return;
        }

        account.baseTokenBalance = balances.baseTokenBalance;

        for (const [address, balance] of Object.entries(
          balances.tokenBalances
        )) {
          if (address in account.tokenBalances) {
            account.tokenBalances[address].balance = balance;
          }
        }
      }
    },

    addWithdrawal(state, payload) {
      const withdrawalIndex = state.withdrawals.findIndex(
        (withdrawal: any) => withdrawal.id === payload.id
      );
      if (withdrawalIndex < 0) {
        state.withdrawals.push(payload);
      } else {
        state.withdrawals[withdrawalIndex] = payload;
      }
    },

    addDeposit(state, payload) {
      state.deposits.push(payload);
    },

    addSnipe(state, payload) {
      const snipeIndex = state.snipes.findIndex(
        (snipe: any) => payload.id === snipe.id
      );

      if (snipeIndex < 0) {
        state.snipes.push(payload);
      } else {
        state.snipes[snipeIndex] = payload;
      }

      const addTransaction = (
        snipe: any,
        chain: any,
        swap: any,
        pair: any,
        payload: any
      ) => {
        if (payload === undefined) return;

        const transactionIndex = state.transactions.findIndex(
          (transaction: any) => transaction.txId === payload.txId
        );
        if (transactionIndex < 0) {
          state.transactions.push({
            ...payload,
            chain,
            swap,
            from: pair.from,
            to: pair.to,
            snipe,
          });
        } else {
          state.transactions[transactionIndex] = {
            ...payload,
            chain,
            swap,
            from: pair.from,
            to: pair.to,
            snipe,
          };
        }
      };

      const snipe = state.snipes.find((snipe: any) => payload.id === snipe.id);

      snipe.profit = calculateProfit(snipe);

      for (const tx of payload.transactions) {
        addTransaction(
          snipe,
          snipe.config.chain,
          snipe.config.swap,
          snipe.config.pair,
          tx
        );
      }
    },

    setChain(state, payload) {
      localStorage.setItem("chain", payload);
      state.chain = payload;
    },

    setTokenInfo(state, payload) {
      state.tokenInfo = payload.reduce((accumulator: any, tokenInfo: any) => {
        if (!(tokenInfo.chain in accumulator)) {
          accumulator[tokenInfo.chain] = {};
        }
        accumulator[tokenInfo.chain][tokenInfo.address] = tokenInfo;
        return accumulator;
      }, {});
      localStorage.setItem("tokenInfo", JSON.stringify(state.tokenInfo));
    },
  },
  actions: {
    setInitialLoading({ commit }, payload) {
      commit("basic", {
        key: "initialLoadRunning",
        value: payload,
      });
    },

    setStyle({ commit }, payload) {
      const style: any = (styles as any)[payload] ?? styles.basic;

      document.body.className = style.body;
      document.documentElement.className = style.html;

      if (localStorage[styleKey] !== payload) {
        localStorage.setItem(styleKey, payload);
      }

      commit("styles", style);
    },

    asideMobileToggle({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded;

      document
        .getElementById("app")
        ?.classList[isShow ? "add" : "remove"]("ml-60", "lg:ml-0");

      document.documentElement.classList[isShow ? "add" : "remove"](
        "m-clipped"
      );

      commit("basic", {
        key: "isAsideMobileExpanded",
        value: isShow,
      });
    },

    asideLgToggle({ commit, state }, payload = null) {
      commit("basic", {
        key: "isAsideLgActive",
        value: payload !== null ? payload : !state.isAsideLgActive,
      });
    },

    fullScreenToggle({ commit }, value) {
      commit("basic", { key: "isFullScreen", value });

      document.documentElement.classList[value ? "add" : "remove"](
        "full-screen"
      );
    },

    darkMode({ commit, state }, payload = null) {
      const value = payload !== null ? payload : !state.darkMode;

      document.documentElement.classList[value ? "add" : "remove"]("dark");

      localStorage.setItem(darkModeKey, value ? "1" : "0");

      commit("basic", {
        key: "darkMode",
        value,
      });
    },

    fetch({ commit }, payload) {
      axios
        .get(`data-sources/${payload}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            commit("basic", {
              key: payload,
              value: r.data.data,
            });
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    },

    readUserDetails({ commit }) {
      axios
        .get(apiBasePath + "/user")
        .then((response) => {
          commit("user", response.data);
          commit("basic", {
            key: "showHiddenBalances",
            value: response.data.showHiddenBalances,
          });
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    readAccounts({ commit }) {
      axios
        .get(apiBasePath + "/account")
        .then((response) => {
          commit("basic", {
            key: "accounts",
            value: response.data,
          });
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    async readChainDetails({ commit }) {
      try {
        const response = await axios.get(apiBasePath + "/chains");
        commit("basic", {
          key: "chainDetails",
          value: response.data,
        });
        localStorage.setItem("chains", JSON.stringify(response.data));
        const storedChain = localStorage.getItem("chain");
        if (storedChain) {
          commit("setChain", storedChain);
        } else {
          commit("setChain", (Object.values(response.data)[0] as any).key);
        }
      } catch (error) {
        showApiErrorResponse(error);
      }
    },

    loadLocale({ commit }) {
      const locale = localStorage.getItem("locale");
      if (locale) {
        commit("basic", { key: "locale", value: locale });
      }
    },

    setLocale({ commit }, payload) {
      localStorage.setItem("locale", payload);
      commit("basic", { key: "locale", value: payload });
    },

    readTokenInfo({ commit }) {
      axios
        .get(apiBasePath + "/tokens")
        .then((response) => {
          commit("setTokenInfo", response.data);
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    createAccount({ state }) {
      axios
        .post(apiBasePath + "/account/" + state.chain, {
          userId: state.auth.userId,
        })
        .then(() => {
          showSuccess("account-created");
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    importAccount({ state }, payload) {
      axios
        .post(apiBasePath + "/account/" + state.chain + "/import", payload)
        .then(() => {
          showSuccess("account-imported");
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    updateUser({ commit }, payload) {
      axios
        .put(apiBasePath + "/user", {
          email: payload.email,
          name: payload.name,
          showHiddenBalances: payload.showHiddenBalances,
          showZeroBalances: payload.showZeroBalances,
        })
        .then(() => {
          if (!payload.dontShowSuccessMessage) {
            showSuccess("user-updated");
          }
          commit("user", payload);
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    updateAccount({ commit, state }, payload) {
      axios
        .patch(
          apiBasePath + "/account/" + state.chain + "/" + payload.publicKey,
          {
            name: payload.request.name ?? "",
          }
        )
        .then(() => {
          showSuccess("account-updated");
          commit("account", {
            ...payload.request,
            publicKey: payload.publicKey,
          });
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    withdraw(_, payload) {
      axios
        .post(apiBasePath + "/withdraw", payload)
        .then(() => {
          showSuccess("withdrawal-created");
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    readWithdrawals({ commit }) {
      axios
        .get(apiBasePath + "/withdraw")
        .then((response) => {
          commit("basic", {
            key: "withdrawals",
            value: response.data,
          });
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    readDeposits({ commit }) {
      axios
        .get(apiBasePath + "/deposit")
        .then((response) => {
          commit("basic", {
            key: "deposits",
            value: response.data,
          });
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    readActiveSnipes({ commit }) {
      axios
        .get(apiBasePath + "/snipe/active")
        .then((response) => {
          for (const snipe of response.data) {
            commit("addSnipe", snipe);
          }
        })
        .catch((error) => {
          showApiErrorResponse(error);
        });
    },

    async readSnipeStatistics({ commit }) {
      try {
        const response = await axios.get(apiBasePath + "/snipe/statistics");
        commit("basic", {
          key: "snipeStatistics",
          value: response.data,
        });

        for (const statistic of Object.values(response.data)) {
          for (const snipe of (statistic as any).topSnipes) {
            commit("addSnipe", snipe);
          }
        }
      } catch (error) {
        showApiErrorResponse(error);
      }
    },

    fetchPrices({ commit, state, getters }) {
      const tokens = new Set();

      if (state.auth.loggedIn && state.accounts.length) {
        for (const account of state.accounts) {
          for (const token of Object.keys(account.tokenBalances)) {
            tokens.add({
              chain: account.chain,
              swap: getters.chainDetails.defaultSwap,
              address: token,
            });
          }
        }

        axios
          .post(apiBasePath + "/price", [...tokens])
          .then((prices) => {
            commit("basic", { key: "prices", value: prices.data });
          })
          .catch((error) => {
            showApiErrorResponse(error);
          });
      }
    },

    toggleHiddenBalances({ commit, state, dispatch }) {
      commit("basic", {
        key: "showHiddenBalances",
        value: !state.showHiddenBalances,
      });
      dispatch("updateUser", {
        showZeroBalances: state.showHiddenBalances,
        showHiddenBalances: state.showHiddenBalances,
        dontShowSuccessMessage: true,
      }).then(() => {
        dispatch("readAccounts");
      });
    },
  },
  modules: {
    auth: authModule as Module<AuthState, RootState>,
    packages: packageModule,
    presets: presetModule,
    fees: feeModule,
    giftCodes: giftCodeModule,
    vouchers: voucherModule,
    snipeSignals: snipeSignalsModule,
    snipeAutomationConfiguration: snipeAutomationConfigurationsModule,
    userAdministration: userAdministrationModule,
    copyTradeConfiguration: copyTradeConfigurationModule,
    copyTradeAccount: copyTradeAccountModule,
    notifications: notificationsModule,
    notificationAdministration: notificationAdministrationModule,
    statisticsAdministration: statisticsAdministrationModule,
  },
});
